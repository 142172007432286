import React, { ComponentProps, useState } from 'react'

import { Checkbox, CheckboxGroup, FormText } from '@te-digi/styleguide'

const CheckboxHelpExample = () => {
  const [value, setValue] = useState<
    ComponentProps<typeof CheckboxGroup>['value']
  >(['1'])

  return (
    <>
      <CheckboxGroup
        subLabel="Valitse yksi tai useampi vaihtoehto."
        label="Valitse sopivat vaihtoehdot"
        name="checkbox-group-example-1"
        onChange={e => setValue(e)}
        value={value}
        help="Valintaan liittyvä tarkempi lisäohjeistus, joka saattaa olla pitkäkin teksti eikä sitä näin ollen haluta pitää koko ajan näkyvillä"
      >
        <Checkbox
          value="1"
          help="Ensimmäisen vaihtoehdon lyhyt lisäohjeistus"
        >
          Vaihtoehto 1
        </Checkbox>
        <Checkbox value="2">Vaihtoehto 2 </Checkbox>
      </CheckboxGroup>
      <FormText>Valittu: {value?.join(', ')}</FormText>
    </>
  )
}

export { CheckboxHelpExample }
