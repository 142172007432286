import React from 'react'
import {
  Checkbox,
  CheckboxGroup,
  FormGroup,
  Help,
  Input,
  Label,
  List,
  ListItem,
  Paragraph,
  Spacer
} from '@te-digi/styleguide'

import { CheckboxExample1 } from '../../examples/react/CheckboxExample1'
import { CheckboxGroupDescriptionExample } from '../../examples/react/CheckboxGroupDescriptionExample'
import { CheckboxGroupExample1 } from '../../examples/react/CheckboxGroupExample1'
import { CheckboxConditionalExample } from '../../examples/react/CheckboxConditionalExample'
import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import CustomControlHTMLExample from '../../examples/html/CustomControlHTMLExample'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'
import { CheckboxHelpExample } from '../../examples/react/CheckboxHelpExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Checkbox"
    components={[
      { component: Checkbox, restElement: 'input' },
      { component: CheckboxGroup }
    ]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section title="CheckboxGroup">
      <Paragraph>
        Saman kysymykseen vastaavat tai muuten yhteen kuuluvat{' '}
        <Code>Checkbox</Code>it kootaan ryhmäksi <Code>CheckboxGroup</Code>
        -komponentilla.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Ryhmä luo kontekstin, joka huolehtii <Code>checked</Code>,{' '}
          <Code>name</Code> ja <Code>onChange</Code> -propsit yksittäisten
          Checkbox-komponenttien puolesta.
        </ListItem>
        <ListItem>Ryhmälle voidaan myös näyttää yhteinen virheviesti.</ListItem>
        <ListItem>
          Erikoistapauksissa ryhmä voidaan luoda myös manuaalisesti{' '}
          <Code>FormGroup</Code>-komponentilla (kts. myöhemmät esimerkit).
        </ListItem>
      </List>

      <Help>
        <Paragraph noMargin>
          <Code>CheckboxGroup</Code>ia ei tarvitse ympäröidä{' '}
          <Code>FormGroup</Code>illa, komponentti tuottaa sen automaattisesti.
        </Paragraph>
      </Help>

      <Playground
        example={CheckboxGroupExample1}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div
          aria-labelledby="checkbox-html-example-1-label"
          className="form-group mb-0"
          role="group"
        >
          <LabelHTMLExample
            as="p"
            id="checkbox-html-example-1-label"
          >
            Ilmianna asiaton työilmoitus
          </LabelHTMLExample>
          <CustomControlHTMLExample
            defaultChecked
            id="checkbox-html-example-1-1"
            name="checkbox-html-example-1"
            type="checkbox"
          >
            Ilmoitus on lainvastainen tai syrjivä
          </CustomControlHTMLExample>
          <CustomControlHTMLExample
            id="checkbox-html-example-1-2"
            name="checkbox-html-example-1"
            type="checkbox"
          >
            Työllistäjä pyytää rahaa tai etumaksua työhaastatteluun pääsemiseksi
          </CustomControlHTMLExample>
          <CustomControlHTMLExample
            defaultChecked
            disabled
            id="checkbox-html-example-1-3"
            name="checkbox-html-example-1"
            type="checkbox"
          >
            Samalla työllistäjällä on monta samanlaista työilmoitusta auki
          </CustomControlHTMLExample>
          <CustomControlHTMLExample
            disabled
            id="checkbox-html-example-1-4"
            name="checkbox-html-example-1"
            type="checkbox"
          >
            Työilmoituksessa on käytetty hyvin asiatonta kieltä
          </CustomControlHTMLExample>
        </div>
      </Playground>
      <SectionHeading>Virheviesti</SectionHeading>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <CheckboxGroup
          error="Valitse vähintään yksi"
          label="Valitse sopivat vaihtoehdot"
        >
          <Checkbox
            value="1"
            onChange={() => {}}
          >
            Vaihtoehto 1
          </Checkbox>
          <Checkbox
            value="2"
            onChange={() => {}}
          >
            Vaihtoehto 2
          </Checkbox>
          <Checkbox
            value="3"
            onChange={() => {}}
          >
            Vaihtoehto 3
          </Checkbox>
        </CheckboxGroup>
      </Playground>
    </Section>

    <Section title="Yksittäinen Checkbox">
      <Paragraph>
        <Code>Checkbox</Code> ilman <Code>CheckboxGroup</Code>ia ympäröidään{' '}
        <Code>FormGroup</Code>-elementillä.
      </Paragraph>

      <SectionHeading>Tekstikentän yhteydessä</SectionHeading>
      <Paragraph>
        <Code>Checkbox</Code>ia ilman <Code>CheckboxGroup</Code>ia käytetään
        tyypillisesti silloin, kun näytetään yksittäinen <Code>Checkbox</Code>{' '}
        esim. <Code>Input</Code>-kentän yhteydessä.
      </Paragraph>
      <Paragraph>
        Tekstikenttään liittyvä <Code>Checkbox</Code> erotellaan <Code>xs</Code>
        -marginaalilla.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup
          ariaLabelledby="checkbox-example-3-label"
          noMargin
        >
          <Label htmlFor="checkbox-example-3-input">
            Työnantajan tai yrityksen nimi
          </Label>
          <Input id="checkbox-example-3-input" />
          <Spacer marginTop="xs">
            <Checkbox error="Sinun on hyväksyttävä ehdot, ennen kuin voit jatkaa">
              Piilota työnantajan nimi julkaistussa profiilissa
            </Checkbox>
          </Spacer>
        </FormGroup>
      </Playground>
    </Section>

    <Section title="Lisäohjeistus">
      <SectionHeading>Kuvausteksti</SectionHeading>
      <Paragraph>
        Valintaa helpottava lisäohjeistus annetaan ensisijaisesti labelin alla
        näytettävänä lisätekstinä.
      </Paragraph>
      <Playground
        example={CheckboxGroupDescriptionExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <SectionHeading>Infopainike</SectionHeading>
      <Paragraph>
        Kaikkia checkboxeja koskeva lisäohjeistus annetaan labelin yhteydessä.
      </Paragraph>
      <Paragraph>
        Tiettyyn valintaan liittyvä lisäohjeistus voidaan myös antaa valinnan
        vieressä näytettävänä infopainikkeena. Huomaa, että vaihtoehdon
        yhteydessä olevasta infopainikkesta aukeava teksti käyttäytyy
        ruudunlukijalla samalla tavalla kuin kuvausteksti (
        <Code>description</Code>-prop), eli ohjeteksti luetaan heti vaihtoehdon
        labelin jälkeen automaattisesti, vaikka näkevälle teksti onkin
        piilotettuna.
      </Paragraph>
      <Playground
        WrapperComponent={FormLayoutWrapper}
        example={CheckboxHelpExample}
      />
    </Section>
    <Section title="Sisäkkäiset Checkboxit">
      <Paragraph>
        Tässä esimerkissä <Code>Checkbox</Code>it on ryhmitelty{' '}
        <Code>FormGroup</Code>-komponentilla ja käytetty erillistä{' '}
        <Code>Label</Code>ia.
      </Paragraph>

      <Playground
        example={CheckboxExample1}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Ehdolliset lisäkentät">
      <Paragraph>
        <Code>CheckboxGroup</Code>in sisään voi lisätä lisäkenttiä valintojen
        väliin, mutta tämä ei ole suositeltua. Suositeltu tapa on lisätä
        valinnoista riippuvat lisäkentät <Code>CheckboxGroup</Code>in perään.
      </Paragraph>
      <Playground
        example={CheckboxConditionalExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Saavutettavuus">
      <List variant="unordered">
        <ListItem>
          Käytä valintaruutua, kun käyttäjä voi valita yhden tai useamman
          vaihtoehdon.
        </ListItem>
        <ListItem>
          Yhden checkboxin valitseminen ei saisi koskaan vaikuttaa toiseen
          valintaan (eli valita automaattisesti toisia vaihtoehtoja).
        </ListItem>
        <ListItem>
          Checkbox ei saa käynnistää toimintoja, vaan valinta pitää aina
          vahvistaa toisella toiminnalla.
        </ListItem>
        <ListItem>
          On suositeltua käyttää maksimissaan kolmea sanaa valintaruudun
          labelissa.
        </ListItem>
        <ListItem>
          Varmista, että ryhmällä on kuvaava nimi. Ei ole suositeltua käyttää
          ryhmää ilman labelia.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
