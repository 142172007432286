import React, { ComponentProps, useState } from 'react'

import { Checkbox, CheckboxGroup, FormText, Input } from '@te-digi/styleguide'

const CheckboxConditionalExample = () => {
  const [value, setValue] = useState<
    ComponentProps<typeof CheckboxGroup>['value']
  >(['1'])
  const [inputValue1, setInputValue1] = useState('')
  const [inputValue2, setInputValue2] = useState('')

  return (
    <>
      <CheckboxGroup
        label="Valitse sopivat vaihtoehdot"
        name="checkbox-conditional-example-1"
        onChange={e => setValue(e)}
        value={value}
      >
        <Checkbox
          conditional={
            <Input
              label="Lisätietoa vaihtoehdolle 1"
              onChange={event => setInputValue1(event.target.value)}
              value={inputValue1}
            />
          }
          value="1"
        >
          Vaihtoehto 1
        </Checkbox>
        <Checkbox
          conditional={
            <Input
              label="Lisätietoa vaihtoehdolle 2"
              onChange={event => setInputValue2(event.target.value)}
              value={inputValue2}
            />
          }
          value="2"
        >
          Vaihtoehto 2
        </Checkbox>
      </CheckboxGroup>
      <FormText>Valittu: {value?.join(', ')}</FormText>
    </>
  )
}

export { CheckboxConditionalExample }
