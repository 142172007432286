import React, { ComponentProps, useState } from 'react'

import { Checkbox, CheckboxGroup, FormText } from '@te-digi/styleguide'

const CheckboxGroupExample1 = () => {
  const [value, setValue] = useState<
    ComponentProps<typeof CheckboxGroup>['value']
  >(['1'])

  return (
    <>
      <CheckboxGroup
        subLabel="Valintaan liittyvä lisäohjeistus"
        label="Valitse sopivat vaihtoehdot"
        name="checkbox-group-example-1"
        onChange={e => setValue(e)}
        value={value}
      >
        <Checkbox value="1">Vaihtoehto 1</Checkbox>
        <Checkbox value="2">Vaihtoehto 2 </Checkbox>
        <Checkbox
          disabled
          value="3"
        >
          Disabloitu vaihtoehto 3
        </Checkbox>
      </CheckboxGroup>
      <FormText>Valittu: {value?.join(', ')}</FormText>
    </>
  )
}

export { CheckboxGroupExample1 }
