import React, { ComponentProps, useState } from 'react'

import { Checkbox, CheckboxGroup, SmallText, Spacer } from '@te-digi/styleguide'

const CheckboxGroupDescriptionExample = () => {
  const [value, setValue] = useState<
    ComponentProps<typeof CheckboxGroup>['value']
  >(['1'])

  return (
    <CheckboxGroup
      label="Valitse vaihtoehdot"
      name="checkbox-example-2"
      onChange={e => setValue(e)}
      value={value}
    >
      <Checkbox
        description={
          <SmallText noMargin>
            Mauris sed libero. Suspendisse facilisis nulla in lacinia laoreet,
            lorem velit accumsan velit vel mattis libero nisl et sem. Proin
            interdum maecenas massa turpis sagittis in, interdum non lobortis
            vitae massa.
          </SmallText>
        }
        value="1"
      >
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit
      </Checkbox>
      <Spacer marginBottom="lg" />
      <Checkbox
        description={
          <SmallText noMargin>
            Nam molestie nec tortor. Donec placerat leo sit amet velit.
            Vestibulum id justo ut vitae massa. Proin in dolor mauris consequat
            aliquam. Donec ipsum, vestibulum ullamcorper venenatis augue.
          </SmallText>
        }
        value="2"
      >
        Mauris varius diam vitae arcu
      </Checkbox>
    </CheckboxGroup>
  )
}

export { CheckboxGroupDescriptionExample }
