import React, { ReactNode } from 'react'

type CustomControlHTMLExampleProps = {
  children: ReactNode
  defaultChecked?: boolean
  disabled?: boolean
  id: string
  name: string
  type: 'checkbox' | 'radio'
  value?: string
}

const CustomControlHTMLExample = ({
  children,
  defaultChecked,
  disabled,
  id,
  name,
  type,
  value
}: CustomControlHTMLExampleProps) => (
  <div className={`custom-control custom-${type}`}>
    <div className="custom-control-body">
      <input
        className="custom-control-input"
        disabled={disabled}
        id={id}
        onChange={() => {
          // This is intentional
        }}
        type={type}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  </div>
)

export default CustomControlHTMLExample
