import React, { useState } from 'react'

import {
  Checkbox,
  FormGroup,
  FormIndent,
  Label,
  Spacer
} from '@te-digi/styleguide'

const CheckboxExample1 = () => {
  const [isCheckbox1Checked, setIsCheckbox1Checked] = useState(true)
  const [isCheckbox2Checked, setIsCheckbox2Checked] = useState(false)
  const [checkboxes, setCheckboxes] = useState([true, false])

  return (
    <FormGroup
      ariaLabelledby="checkbox-example-1-label"
      noMargin
    >
      <Label
        as="p"
        id="checkbox-example-1-label"
      >
        Ilmianna asiaton työilmoitus
      </Label>
      <Checkbox
        checked={isCheckbox1Checked}
        name="checkbox-example-1"
        onChange={() => setIsCheckbox1Checked(!isCheckbox1Checked)}
      >
        Ilmoitus on lainvastainen tai syrjivä
      </Checkbox>
      <Checkbox
        checked={isCheckbox2Checked}
        id="checkbox-example-1-2"
        name="checkbox-example-1"
        onChange={() => setIsCheckbox2Checked(!isCheckbox2Checked)}
      >
        Työllistäjä pyytää rahaa tai etumaksua työhaastatteluun pääsemiseksi
      </Checkbox>
      <Checkbox
        disabled
        id="checkbox-example-1-3"
        name="checkbox-example-1"
      >
        Samalla työllistäjällä on monta samanlaista työilmoitusta auki
      </Checkbox>
      <Checkbox
        checked={checkboxes.every(Boolean)}
        id="checkbox-example-1-5"
        indeterminate={checkboxes.some(Boolean) && !checkboxes.every(Boolean)}
        name="checkbox-example-1"
        onChange={() =>
          setCheckboxes(
            checkboxes.every(Boolean) ? [false, false] : [true, true]
          )
        }
        required
        visuallyRequired
      >
        Työilmoituksessa on käytetty hyvin asiatonta kieltä
      </Checkbox>
      <Spacer
        marginLeft="xl"
        marginTop="xs"
      >
        <FormIndent ariaLabelledby="checkbox-example-1-5">
          <Checkbox
            checked={checkboxes[0]}
            id="checkbox-example-1-6"
            name="checkbox-example-1"
            onChange={() => setCheckboxes(values => [!values[0], values[1]])}
          >
            Lorem ipsum dolor
          </Checkbox>
          <Checkbox
            checked={checkboxes[1]}
            id="checkbox-example-1-7"
            name="checkbox-example-1"
            onChange={() => setCheckboxes(values => [values[0], !values[1]])}
          >
            Sed posuere interdum sem
          </Checkbox>
        </FormIndent>
      </Spacer>
    </FormGroup>
  )
}

export { CheckboxExample1 }
